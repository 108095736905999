import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

let sessionUrl = null;

export const setupLogRocket = () => {
  console.log(`Check env for LogRocket configuration: ${process.env.NODE_ENV}`);
  const isEnabled = process.env.NODE_ENV == "production" && localStorage.getItem("logRocketDisabled") !== "true";
  if (isEnabled) {
    LogRocket.init('zi2jjz/craicbox');
    LogRocket.getSessionURL((sessionURL) => {
      console.log(`LogRocket session URL: ${sessionURL}`);
      sessionUrl = sessionURL;
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('logrocket_url', sessionURL);
        // We know the newrelic session ID is available after the page has loaded so as 
        // a hack we log it here.
        logNewRelicSessionId();
      }
    });
    setupLogRocketReact(LogRocket);
  } else {
    console.log("Disabled LogRocket");
  }
};

const logNewRelicSessionId = () => {
  try {
    Object.values(window.newrelic.initializedAgents)
      .map(agent => {
        console.log(`NewRelic session ID: ${agent.runtime.session.value}`);
      });
  } catch (err) {
    console.log(`Could not determine newrelic session ID: ${err}`);
  }
};

export const getLogRocketHeaders = () => {
  return {
    'X-LogRocket-URL': sessionUrl 
  };
};

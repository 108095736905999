import React, { useState, useEffect } from "react";
import Logo from "../../images/Logo.png";
import Feedback from "./Feedback";

function ErrorFallback({ error }) {
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    const handler = e => setIsDesktop({ isDesktop: e.matches});
    window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
  }, []);

  // Define a css grid with one column with center aligned content, evenly vertically spaced
  const gridStyle = {
    marginTop: "10vh",
    display: "grid",
    gridTemplateColumns: "1fr",
    textAlign: "center",
    gridRowGap: "2vh"
  };
  const textStyle = {
    textAlign: "center",
    fontSize: "xx-large"
  };
  const errorStyle = {
    textAlign: "center",
    color: "red",
    width: "75%",
    margin: "auto",
    textWrap: "wrap",
    font: "monospace"
  };
  const imageStyle = {
    margin: "auto",
    width: isDesktop ? "25%" : "50%",
  };
  return (<div style={gridStyle}>
      <img style={imageStyle} src={Logo} alt="Logo"></img>
      <div style={textStyle}>Something went wrong...</div>
      <div style={errorStyle}>{error.message}</div>
      <Feedback />
    </div>);
}

export default ErrorFallback;
export const iconStyle = {
  top: "2px",
  position: "relative",
  margin: "2px"
};
export const buttonTextStyle = {
  margin: "2px",
};
export const centerRowContentStyle = {
  marginLeft: 0,
  marginRight: 0,
  textAlign: "center",
  marginBottom: "5px"
};

export const centerTitleContentStyle = {
  textAlign: "center",
  margin: "15px",
  fontSize: "medium",
};

export const tabsStyle = {
  display: "inline-block",
  margin: "1vh 2vw",
  paddingBottom: "2vh",
  border: "solid 1px lightgrey",
  background: "white"
};

export const alertStyle = {
  display: "inline-block",
  marginBottom: 0,
  padding: "1vh 1vw",
  margin: "1vh 1vw"
};

export const inputStyle = {
  outline: "thin",
  padding: "0.5vh 0.5vw",
  margin: "1vh 1vw"
}

export const craicboxGreen = "#43b659";
export const craicboxDarkGreen = "#2d925f";
export const craicboxPurple = "rgb(64, 85, 202)";
import React, { Suspense } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RouteHandler from "./RouteHandler";
import store, { persistor } from "./redux/Store";
import { setupLogRocket } from "./services/LogRocketService";
import ConsentForm from "./components/views/ConsentForm";
import Loading from "./components/common/Loading";
import ErrorFallback from "./components/common/ErrorFallback";

import "./App.css";

const CreateRoom = React.lazy(() => import("./components/views/CreateRoom"));
const JoinRoom = React.lazy(() => import("./components/views/JoinRoom"));
const About = React.lazy(() => import("./components/views/About"));
const PickGame = React.lazy(() => import("./components/views/PickGame"));
const AddFriends = React.lazy(() => import("./components/views/AddFriends"));
const Play = React.lazy(() => import("./components/views/Play"));
const PaymentResult = React.lazy(() => import("./components/views/PaymentResult"));
const TermsAndConditions = React.lazy(() => import("./components/views/TermsAndConditions"));
const PrivacyPolicy = React.lazy(() => import("./components/views/PrivacyPolicy"));
const Admin = React.lazy(() => import("./components/views/Admin"));

window.store = store;

// Allow console.log to be used in production
if (typeof console === "undefined") {
  console = { log: function() { } };
}

function App() {
  setupLogRocket();
  const queryClient = new QueryClient();
  const protectedRoute = (Component) => {
    return (props) => {
      return <ConsentForm content={<Component {...props} />} />;
    };
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <Suspense fallback={<Loading />}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <div>
                  <RouteHandler />
                  <Switch>
                    <Redirect exact from="/" to="/create-room" />
                    <Route path="/create-room" render={protectedRoute(CreateRoom)} />
                    <Route path="/join-room" render={protectedRoute(JoinRoom)} />
                    <Route path="/about" component={About} />
                    <Route path="/room/:roomCode/pick-game" render={protectedRoute(PickGame)} />
                    <Route path="/room/:roomCode/add-friends" render={protectedRoute(AddFriends)} />
                    <Route path="/room/:roomCode/play" render={protectedRoute(Play)} />
                    <Route path="/room/:roomCode/about" render={protectedRoute(About)} />
                    <Route path="/admin" render={protectedRoute(Admin)} />
                    <Route path="/payment-result" render={protectedRoute(PaymentResult)} />
                    <Route path="/terms-and-conditions" component={TermsAndConditions} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                  </Switch>
                </div>
              </BrowserRouter>
            </QueryClientProvider>
          </PersistGate>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
